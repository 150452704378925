import MobileStepOne from '../@icons/images/mobile/Beranda Mobile 1.png';
import MobileStepTwo from '../@icons/images/mobile/Profile Mobile 1.png';
import MobileStepThird from '../@icons/images/mobile/pengaturan akun mobile 1.png';
import MobileStepFourth from '../@icons/images/mobile/Keaman Mobile 1.png';
import MobileStepFive from '../@icons/images/mobile/detail hapus akun mobile 1.png';
import MobileStepSix from '../@icons/images/mobile/alasan mobile 1.png';
import MobileStepSeven from '../@icons/images/mobile/konfirmasi alasan mobile 1.png';
import MobileStepEight from '../@icons/images/mobile/otp mobile 1.png';
import MobileStepNine from '../@icons/images/mobile/sukses mobile 1.png';

import WebStepOne from '../@icons/images/website/Home 2.png';
import WebStepTwo from '../@icons/images/website/profil 3.png';
import WebStepThird from '../@icons/images/website/kemanan 3.png';
import WebStepFourth from '../@icons/images/website/kemanan 3 (1).png';
import WebStepFive from '../@icons/images/website/detail hapus akun 2.png';
import WebStepSix from '../@icons/images/website/alasan 1.png';
import WebStepSeven from '../@icons/images/website/konfrimasi alasan web 1.png';
import WebStepEight from '../@icons/images/website/otp 1.png';
import WebStepNine from '../@icons/images/website/sukses ajukan 1.png';

export const textSection = {
  heroWording: 'Kebijakan Penghapusan Akun',
  descHero: `Kami menghargai privasi dan hak Anda untuk mengelola data pribadi
            yang disimpan oleh Aplikasi MBG. Namun, terdapat beberapa syarat dan
            ketentuan yang perlu dipahami sebelum melakukan penghapusan akun.
            Syarat dan ketentuan ini bertujuan untuk melindungi kepentingan
            pengguna dan layanan kami.`,
};

export const dummies = [
  {
    wording: `Penghapusan akun mencakup semua data pribadi yang terkait dengan akun pengguna, seperti nama, alamat, riwayat pesanan, dan informasi pembayaran.`,
  },
  {
    wording: `Penghapusan akun bersifat permanen dan tidak dapat dibatalkan. Data yang dihapus tidak dapat dipulihkan.`,
  },
  {
    wording: `Setelah akun dihapus, pengguna tidak akan lagi memiliki akses ke aplikasi, riwayat pesanan, atau layanan lain yang disediakan oleh Aplikasi MBG.`,
  },
  {
    wording: `Pengguna bertanggung jawab untuk menyelesaikan transaksi atau kewajiban yang tertunda sebelum mengajukan penghapusan akun.`,
  },
  {
    wording: `Pengguna harus mengosongkan saldo sebelum melakukan penghapusan akun.`,
  },
  {
    wording: `Pengguna tidak akan lagi menerima notifikasi, email promosi, atau komunikasi lainnya dari Aplikasi MBG setelah akun dihapus.`,
  },
  {
    wording: `Jika pengguna ingin menggunakan kembali layanan Aplikasi MBG, mereka harus membuat akun baru dan memulai dari awal tanpa akses ke riwayat atau preferensi akun sebelumnya.`,
  },
  {
    wording: `Beberapa data mungkin tidak dapat dihapus karena kewajiban hukum atau kebutuhan operasional, termasuk namun tidak terbatas pada catatan transaksi yang diperlukan untuk tujuan audit, perpajakan, atau penyelesaian sengketa.`,
  },
];

export const stepsMobileDatas = [
  {
    number: 1,
    wording: `Pilih menu “Profil” di halaman utama`,
    picture: MobileStepOne,
  },
  {
    number: 2,
    wording: `Lalu klik fitur “Pengaturan Akun” di sidebar`,
    picture: MobileStepTwo,
  },
  {
    number: 3,
    wording: 'Cari opsi “Keamanan Akun”',
    picture: MobileStepThird,
  },
  {
    number: 4,
    wording: 'Kemudian klik “Hapus Akun”',
    picture: MobileStepFourth,
  },
  {
    number: 5,
    wording:
      `Sebelum lanjut, Anda harus memenuhi persyaratan penghapusan akun diantaranya menambahkan email aktif, 
      mengosongkan saldo, dan menyelesaikan pesanan untuk mengajukan permintaan hapus akun`,
    picture: MobileStepFive,
  },
  {
    number: 6,
    wording:
      'Setelah itu, Anda harus memilih dan memberikan penjelasan detail alasan menutup akun',
    picture: MobileStepSix,
  },
  {
    number: 7,
    wording:
      'Pilih opsi “Konfirmasi” untuk melanjutkan proses penghapusan akun',
    picture: MobileStepSeven,
  },
  {
    number: 8,
    wording:
      'Berikutnya, masukkan kode OTP yang telah kami kirimkan ke email Anda untuk proses verifikasi permintaan penghapusan akun',
    picture: MobileStepEight,
  },
  {
    number: 9,
    wording:
      'Setelah klik “Lanjut” permintaan penghapusan akun Anda akan diproses dan membutuhkan waktu 1-3 hari kerja hingga permintaan dikabulkan',
    picture: MobileStepNine,
  },
];

export const stepsWebsiteDatas = [
  {
    number: 1,
    wording: 'Pilih menu “Profil” di halaman utama',
    picture: WebStepOne,
  },
  {
    number: 2,
    wording: 'Lalu klik fitur “Pengaturan Akun” di sidebar',
    picture: WebStepTwo,
  },
  {
    number: 3,
    wording: 'Cari opsi “Keamanan Akun”',
    picture: WebStepThird,
  },
  {
    number: 4,
    wording: 'Kemudian klik “Hapus Akun”',
    picture: WebStepFourth,
  },
  {
    number: 5,
    wording:
      'Sebelum lanjut, Anda harus memenuhi persyaratan penghapusan akun diantaranya menambahkan email aktif, mengosongkan saldo, dan menyelesaikan pesanan untuk mengajukan permintaan hapus akun',
    picture: WebStepFive,
  },
  {
    number: 6,
    wording:
      'Setelah itu, Anda harus memilih dan memberikan penjelasan detail alasan menutup akun',
    picture: WebStepSix,
  },
  {
    number: 7,
    wording:
      'Pilih opsi “Konfirmasi” untuk melanjutkan proses penghapusan akun',
    picture: WebStepSeven,
  },
  {
    number: 8,
    wording:
      'Berikutnya, masukkan kode OTP yang telah kami kirimkan ke email Anda untuk proses verifikasi permintaan penghapusan akun',
    picture: WebStepEight,
  },
  {
    number: 9,
    wording:
      'Setelah klik “Lanjut” permintaan penghapusan akun Anda akan diproses dan membutuhkan waktu 1-3 hari kerja hingga permintaan dikabulkan',
    picture: WebStepNine,
  },
];
