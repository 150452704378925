import React from 'react';

interface MobileItemCardProps {
  number: number;
  wording: string;
  picture: string;
}

const MobileItemCard: React.FC<MobileItemCardProps> = ({ number, wording, picture }) => {

  return (
    <div className="w-full grid grid-cols-2 sm:flex sm:flex-col gap-10 p-4">
      <div className="flex flex-col col-span-1 order-1 sm:order-none">
        <div className="flex items-start mb-4">
          <span className="font-inter font-normal text-sm text-[#4A4D4F] mr-2">
            {number}.
          </span>
          <p className={`font-inter font-normal text-sm text-[#4A4D4F] md:text-justify`}>
            {wording}
          </p>
        </div>
      </div>
      <div className="flex justify-start items-start col-span-1 order-2 sm:order-none">
        <img
          src={picture}
          alt="pict-mobile"
          className="w-[116px] object-contain"
        />
      </div>
    </div>
  );
};

export default MobileItemCard;