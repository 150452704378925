/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { ArrowBottom, MBGIconOrange } from '../../@icons';
import {
  dummies,
  stepsMobileDatas,
  stepsWebsiteDatas,
  textSection,
} from '../dummy';
import ItemCard from '../../@components/contentWeb';
import MobileItemCard from '../../@components/contentMobile';

const HomePage = () => {
  const [isOpenMainDropdown, setIsOpenMainDropdown] = useState<boolean>(false);
  const [isOpenSecondDropdown, setIsOpenSecondDropdown] =
    useState<boolean>(false);

  const toggleMainDropdown = () => {
    setIsOpenMainDropdown(!isOpenMainDropdown);
  };

  const toggleSecondDropdown = () => {
    setIsOpenSecondDropdown(!isOpenSecondDropdown);
  };
  // md:mx-[160px]
  
  return (
    <main className="w-full box-border bg-[#FFFFFF]">
      <header className="py-3 px-4 md:px-[90px] flex justify-start items-center">
        <MBGIconOrange />
      </header>
      <section className="pt-10 px-4 md:flex justify-center items-center pb-[72px] bg-custom-gradient">
        <div className="flex-col items-center md:w-[754px]">
          <p className="font-inter font-bold text-[24px] sm:text-[28px] md:text-[32px] text-center text-[#EEF9FF] capitalize">
            {textSection?.heroWording}
          </p>
          <p className="font-inter font-normal text-xs sm:text-sm text-center text-[#EEF9FF] mt-6 md:mt-8 mobile:text-justify">
            {textSection?.descHero}
          </p>
        </div>
      </section>
      <section className="mx-[16px] sm:mx-[40px] lg:mx-[160px] mt-6">
        <div className="mb-8">
          <p className="font-inter font-medium text-base md:text-lg capitalize text-[#000000]">
            1. Permintaan Penghapusan Akun
          </p>
          <p className="font-inter font-normal text-xs sm:text-sm text-[#818284] mt-4 text-justify">
            Pengguna memiliki hak untuk meminta penghapusan akun mereka kapan
            saja. Permintaan penghapusan akun dapat dilakukan dengan cara
            berikut:
          </p>
        </div>
        {/* ---> DROPDOWN FIRST  */}
        <div className="space-y-6">
          <div
            onClick={toggleMainDropdown}
            className="flex justify-between items-center cursor-pointer">
            <p className="font-inter font-medium md:text-sm text-xs capitalize text-[#000000]">
              Cara Pengajuan Penghapusan Akun Melalui aplikasi MBG
            </p>
            <div className={`${isOpenMainDropdown && 'rotate-180'}`}>
              <ArrowBottom />
            </div>
          </div>
          {isOpenMainDropdown && (
            <section className="w-full grid grid-cols-1 sm:grid-cols-2 py-5 px-5 mb-8 gap-[114px]">
              {stepsMobileDatas.map((item) => (
                <MobileItemCard
                  key={item?.number}
                  number={item?.number}
                  wording={item?.wording}
                  picture={item?.picture}
                />
              ))}
            </section>
          )}
          {/* ---> DROPDOWN SECOND  */}
          {/* <div
            onClick={toggleSecondDropdown}
            className={`${
              isOpenMainDropdown ? 'pt-[48px]' : ''
            } flex justify-between items-center cursor-pointer`}>
            <p className="font-inter font-medium md:text-sm text-xs capitalize text-[#000000]">
              Cara Pengajuan Penghapusan Akun Melalui website MBG
            </p>
            <div className={`${isOpenSecondDropdown && 'rotate-180'}`}>
              <ArrowBottom />
            </div>
          </div>
          {isOpenSecondDropdown && (
            <section className="w-full grid gap-[74px] py-5 px-5 mb-8 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 sm:grid-cols-1">
              {stepsWebsiteDatas.map((item) => (
                <ItemCard
                  key={item?.number}
                  number={item?.number}
                  wording={item?.wording}
                  picture={item?.picture}
                />
              ))}
            </section>
          )} */}
          <div className={`${!isOpenSecondDropdown ? 'pt-0' : 'pt-[48px]'}`}>
            <p className="font-inter font-medium text-sm sm:text-lg text-[#000000] capitalize">
              2. Ketentuan Umum
            </p>
            {dummies.map((item, index) => (
              <ul key={index} className="grid grid-cols-[auto,1fr] gap-2">
                <li className="marker:text-blue-500 flex items-start">
                  <span className="list-disc list-inside pr-2 text-[#818284]">
                    •
                  </span>
                  <p className="font-inter font-normal text-xs sm:text-sm text-[#818284] mobile:text-justify">
                    {item?.wording}
                  </p>
                </li>
              </ul>
            ))}
          </div>
          <div className="mt-[30px]">
            <div className="flex gap-1">
              <div className=" text-[#1E1E1E] text-sm sm:text-base md:text-lg font-medium">
                3.
              </div>
              <div>
                <div className="text-[#1E1E1E] text-sm sm:text-base md:text-lg font-medium">
                  Perubahan pada Kebijakan Privasi
                </div>
                <div className="text-[#6D6D6D] text-justify text-xs sm:text-sm">
                  Jika Anda memiliki pertanyaan atau kekhawatiran terkait
                  Kebijakan Privasi ini, Anda dapat menghubungi kami melalui:{' '}
                  <br />
                  <br />
                  <strong>Email: official@makanbergizigratis.co.id</strong>{' '}
                  <br />
                  <strong>Nomor Telepon: 021-50918077</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="w-full border-t border-[#DDE5E9] mt-20 py-[30px]">
        <div className="mx-4 md:mx-[90px] border-t border-t-[#DDE5E9] pt-[38px]">
          <p className="font-inter font-normal text-sm md:text-base text-center text-[#222831]">
            @ 2024 Makan Bergizi Gratis
          </p>
        </div>
      </footer>
    </main>
  );
};

export default HomePage;
